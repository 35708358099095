<template>
    <b-modal :id="'filemanager-' + name" size="xl" hide-footer hide-header>
        <template v-if="vars.page === 'list'">
            <b-card class="position-relative">
                <i class="fa fa-close btn-close-modal" @click="hide"></i>
                <h5 class="text-center">
                    Choose Media
                </h5>
                <p class="text-muted size12 text-center mb-4">
                    You can choose image that you have upload before
                </p>
                <div id="media_list" class="srollable-list">
                    <b-row class="mb-4 justify-content-between">
                        <b-col sm="3" class="pull-right">
                            <div class="input-group">
                                <input type="text" placeholder="Search image title" v-model="config.search.key"
                                    v-on:keyup.enter="get()" class="form-control form-control-sm" />
                                <div class="input-group-prepend">
                                    <div class="input-group-text" v-on:click="get()">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <template v-if="config.total_data">
                            <b-col v-for="row in config.rows" :key="row.id" sm="2" class="mb-4">
                                <div v-b-tooltip.hover.top="row.title" class="box-media-clickable" v-bind:class="{ 'active': ['thumbnail', 'advertisement', 'pdf'].includes(vars.target) ? Number(row.is_active) === 1 : isSelected(row) }" @click="chooseMedia(row)">
                                    <i v-if="['thumbnail', 'advertisement', 'pdf'].includes(vars.target) ? Number(row.is_active) === 1 : isSelected(row)" class="fa fa-check media-selected"></i>
                                    <div v-if="row.title.substring(row.title.lastIndexOf('.') + 1 ) === 'pdf'" class="media-image">
                                        <b-img-lazy :key="row.file" src="/img/pdf-icon.png" :alt="row.title" class="w-100 h-100" style="object-fit: cover; border-radius: 7px;"></b-img-lazy>
                                        <small class="truncate font-weight-bold">{{row.title}}</small>
                                    </div>
                                    <div v-else-if="row.title.substring(row.title.lastIndexOf('.') + 1 ) === 'mp4'" class="media-image">
                                        <b-img-lazy :key="row.file" src="/img/video-icon.png" :alt="row.title" class="w-100 h-100" style="object-fit: cover; border-radius: 7px;"></b-img-lazy>
                                        <small class="truncate font-weight-bold">{{row.title}}</small>
                                    </div>
                                    <div v-else class="media-image">
                                        <b-img-lazy blank-color="#acacac" :key="row.file" :src="row.file" :alt="row.title" class="w-100 h-100" style="object-fit: cover; border-radius: 7px;"></b-img-lazy>
                                        <small class="truncate font-weight-bold">{{row.title}}</small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <div class="pagination mt-25">
                                    <b-pagination @change="gotoPage" :total-rows="config.total_data" v-model="config.page"
                                        :per-page="config.per_page">
                                    </b-pagination>
                                </div>
                            </b-col>
                        </template>
                        <b-col sm="12" v-else>
                            <b-alert show variant="light" class="text-center">
                                No media availabe
                            </b-alert>
                        </b-col>
                    </b-row>
                </div>

                <template #footer>
                    <b-button type="button" 
                        variant="primary" 
                        class="btn-min-width border-radius-10 float-right" 
                        :disabled="vars.selectedFile ? false : true"
                        @click="sendMedia">
                        Select Media
                    </b-button>
                    <b-button 
                        type="button" 
                        @click="vars.page='upload'" 
                        variant="border-primary" 
                        class="btn-min-width border-radius-10 float-right mr-3">
                        Upload From Computer
                    </b-button>
                </template>
            </b-card>
        </template>
        <template v-else>
            <form class="form-horizontal" @submit.prevent="doUpload">
                <b-card class="position-relative">
                    <i class="fa fa-close btn-close-modal" @click="hide"></i>
                    <h5 class="text-center">
                        Upload Media
                    </h5>
                    <p class="text-muted size12 text-center mb-4">
                        Upload media so that it can be used without having to re-upload
                    </p>
                    <b-row>
                        <ResponseAlert ref="response" />
                        <b-col sm="12" class="mb-4">
                            <div class="box-file" v-bind:class="{ 'is-invalid': errors.has('media')}">
                                <div class="image-input text-center">
                                    <label for="file-input" class="width-auto">
                                        <input id="file-input" type="file" accept="image/png, image/gif, image/jpeg, image/jpg, image/ico, video/mp4, video/x-m4v, video/*, application/pdf" v-on:change="addMediaToModel" multiple hidden />
                                        <i class="fa fa-upload"></i> Choose File
                                    </label>
                                    <input type="hidden" v-model="models.file" v-validate="'required'" data-vv-name="media"/>
                                </div>
                            </div>
                        </b-col>
                        <template v-if="models.file && !['pdf', 'mp4'].includes(models.title.split('.').pop())">
                            <b-row class="w-100" style="padding: 0 15px">
                                <template v-for="(item, index) in listFiles" >
                                    <b-col sm="5" class="mt-3">
                                        <div class="border-image mt-25">
                                            <b-img :src="getUrlOfFile(item.file)" />
                                        </div>
                                        <p style="margin-top: 8px; font-size: 13px;text-align: right;">Size : {{ file.size }} Kb</p>
                                    </b-col>
                                    <b-col sm="7" class="mt-3">
                                        <b-form-group :class="{ 'is-invalid': errors.has(`title_${index}`)}" label="Title">
                                            <b-form-input v-model="item.title" :name="`title_${index}`" v-validate="'required'" :data-vv-name="`title_${index}`">
                                            </b-form-input>
                                            <div v-if="errors.has(`title_${index}`)">
                                                <p class="text-danger">The title field is required</p>
                                            </div>
                                        </b-form-group>
                                        <b-form-group label="Description">
                                            <b-form-textarea v-model="item.description" rows="3" max-rows="6"></b-form-textarea>
                                        </b-form-group>
                                        <b-button @click.prevent="listFiles.splice(index, 1)" variant="danger" class="rounded">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </b-col>
                                </template>
                            </b-row>
                        </template>
                        <template v-else-if="models.file">
                            <b-col sm="5">
                                <div class="border-image">
                                    <b-img v-if="models.title.substring(models.title.lastIndexOf('.') + 1 ) === 'pdf'" 
                                        src="/img/pdf-icon.png"/>
                                    <video v-else-if="models.title.substring(models.title.lastIndexOf('.') + 1 ) === 'mp4'" 
                                        :src="getUrlOfFile(models.file)" controls class="w-100">
                                        Your browser does not support the video tag.
                                    </video>
                                    <b-img v-else :src="getUrlOfFile(models.file)"/>
                                </div>
                                <p style="margin-top: 8px; font-size: 13px;text-align: right;">Size : {{ file.size }} Kb</p>
                            </b-col>
                            <b-col sm="7">
                                <b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
                                    <b-form-input v-model="models.title" v-validate="'required'" data-vv-name="title">
                                    </b-form-input>
                                    <div v-if="errors.has('title')">
                                        <p class="text-danger">{{ errors.first('title') }}</p>
                                    </div>
                                </b-form-group>
                                <b-form-group label="Description">
                                        <b-form-textarea
                                            v-model="models.description"
                                            rows="3"
                                            max-rows="6">
                                        </b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </template>
                    </b-row>
                    <template #footer>
                        <b-button 
                            type="submit" 
                            variant="primary" 
                            class="btn-min-width float-right rounded border-radius-10"
                            :disabled="listFilesLoading"
                        >
                            Save
                        </b-button>
                        <b-button 
                            type="button" 
                            @click="vars.page='list'" 
                            variant="border-primary" 
                            class="btn-min-width border-radius-10 float-right rounded mr-3"
                            :disabled="listFilesLoading"
                        >
                            Cancel
                        </b-button>
                    </template>
                </b-card>
            </form>
        </template>
    </b-modal>
</template>
<style>
    .card-footer {
        border-top: 0;
    }
</style>
<script>
    import api from '@/utils/api.js';
    import table from '@/utils/table.js';
    import ResponseAlert from '@/components/response_alert';
    import { getUrlOfFile, truncate } from '@/utils/helpers.js';
    export default {
        name: 'Filemanager',
        components: {
            ResponseAlert,
        },
        props: {
            name: {
                type: String,
                default: 'modal'
            },
        },
        data() {
            return {
                config: {
                    uri: this.$route.meta.permission.link,
                    api: api.media,
                    all_site: true,
                    rows: [],
                    total_data: 0,
                    total_page: 0,
                    per_page: 24,
                    page: 1,
                    orderby: "id",
                    sort: "desc",
                    search: {
                        key: ""
                    },
                },
                models: {
                    status: "active",
                    title: "",
                    description: "",
                    file: ""
                },
                file:{
                    size: "",
                },
                listFiles: [],
                listFilesLoading: false,
                vars: {
                    page: "list",
                    FormData: new FormData(),
                    target: "",
                    selectedFile: []
                },
                truncate
            }
        },
        methods: {
            show(target) {
                const _ = this;
                _.vars.target = target
                _.$bvModal.show('filemanager-' + _.name);
                _.get();
            },
            hide() {
                this.$bvModal.hide('filemanager-' + this.name);
            },
            get() {
                const _ = this;
                table.get(_.config)
            },
            gotoPage(page) {
                table.gotoPage(this.config, page);
            },
            addMediaToModel(event) {
                const _ = this;
                _.models.file = event.target.files[0];
                _.models.title = _.models.file.name;
                _.file.size = Number(_.models.file.size / 1000).toFixed(1);

                if(!['pdf', 'mp4'].includes(_.models.title.split('.').pop())) {
                    const imageFile = event.target.files
    
                    for (let i = 0; i < event.target.files.length; i++) {
                        const data = {
                            status: 'active',
                            title: imageFile[i].name,
                            file: imageFile[i],
                            description: '',
                            size: Number(imageFile[i].size / 1000).toFixed(1)
                        }
                        _.listFiles.push(data)
                    }
                }
            },
            isSelected(row) {
                const _ = this
                return _.vars.selectedFile.some(item => item.id === row.id);
            },
            unSelectMedia(excludeId){
                const _ = this;
                _.config.rows.map(v => {
                    if(v.id != excludeId){
                        v.is_active = 0;
                    }
                });
            },
            chooseMedia(media){
                const _ = this;

                if(['thumbnail', 'advertisement', 'pdf'].includes(_.vars.target)) {
                    media.is_active = true;
                    _.unSelectMedia(media.id);
                    _.vars.selectedFile = [media];
                    console.log(_.vars.selectedFile)
                } else {
                    if (_.isSelected(media)) {
                        media.is_active = false;
                        _.unSelectMedia(media.id);
                        const index = _.vars.selectedFile.findIndex(item => item.id === media.id);
                        if (index !== -1) {
                            _.vars.selectedFile.splice(index, 1);
                        }
                    } else {
                        media.is_active = true;
                        _.unSelectMedia(media.id);
                        _.vars.selectedFile.push(media);
                    }
                }
            },
            sendMedia(){
                const _ = this;

                const imageExtensions = ['jpg', 'jpeg', 'png'];
                const fileExtensions = ['pdf'];
                
                const extensionMatch = (extensions, file) => {
                    const fileExtension = file.file.substring(file.file.lastIndexOf(".") + 1).toLowerCase();
                    return extensions.includes(fileExtension);
                };

                if (_.vars.selectedFile.length === 0) {
                    return this.$showToast('Error', 'No file selected', 'danger');
                }

                for (const file of _.vars.selectedFile) {
                    if ((_.vars.target === 'thumbnail' || _.vars.target === 'image') && !extensionMatch(imageExtensions, file)) {
                        return this.$showToast('Error', 'Only accept image files', 'danger');
                    } else if (_.vars.target === 'pdf' && !extensionMatch(fileExtensions, file)) {
                        return this.$showToast('Error', 'Only accept document files', 'danger');
                    }
                }

                if(['thumbnail', 'advertisement', 'pdf'].includes(_.vars.target)) {
                    this.$emit('selectMedia', _.vars.target, _.vars.selectedFile[0])
                } else {
                    this.$emit('selectMedia', _.vars.target, _.vars.selectedFile)
                }
                _.hide();
            },
            // method form upload
            getUrlOfFile(file) {
				return getUrlOfFile(file);
			},
            async doUpload() {
                const _ = this

                if (!['pdf', 'mp4'].includes(_.models.title.split('.').pop()) && _.listFiles) {
                    try {
                        _.listFilesLoading = true
                        await Promise.all(_.listFiles.map(async (file) => {
                            const formData = new FormData();
                            formData.append('status', file.status);
                            formData.append('title', file.title);
                            formData.append('description', file.description);
                            formData.append('file', file.file);

                            // eslint-disable-next-line no-useless-catch
                            try {
                                await _.$axios.post(api.media, formData);
                                _.$store.commit('set_spinner', true);
                            } catch (error) {
                                throw error;
                            }
                        }));

                        _.listFiles = [];
                        _.vars.page = 'list';
                        _.config.page = 1;
                        _.get();
                    } catch (error) {
                        _.$refs.response.setResponse(error.response.data.message, 'danger');
                        window.scrollTo(0, 0);
                    } finally {
                        //
                        _.listFilesLoading = false
                        _.$store.commit('set_spinner', false);
                    }
                } else {
                    _.vars.FormData = new FormData();
                    for (const i in _.models) {
                        _.vars.FormData.append(i, _.models[i]);
                    }
                    _.$validator.validate().then(valid => {
                        if (valid) {
                            _.$axios.post(api.media, _.vars.FormData)
                                .then(() => {
                                    this.models = {
                                        status: "active",
                                        title: "",
                                        description: "",
                                        file: ""
                                    },
                                    _.vars.page = 'list';
                                    _.config.page = 1;
                                    _.get();
                                })
                                .catch(error => _.$refs.response.setResponse(error.response.data.message,
                                    'danger'));
                        } else {
                            window.scrollTo(0, 0);
                        }
                    });
                }
            },
        },
    }
</script>
<style scoped>
.pagination ul{
    margin: auto;
}
</style>