import axios from 'axios';

const table = {
    get(config) {
        const params = {
            per_page: config.per_page,
            page: config.page,
            orderby: config.orderby,
            sort: config.sort,
        };
        const additional_params = config.additional_params || {}
        const search = config.search
        return axios.get(config.api, {
            params: Object.assign(params, search, additional_params)
        }).then(res => {
            const {
                total_data,
                rows,
                total_page
            } = res.data.data;
            if (config.pagination_type == 'scroll') {
                config.rows.push.apply(config.rows, rows);
            } else {
                config.rows = rows;
            }
            config.total_data = total_data;
            config.total_page = total_page;
        }).catch((error) => console.log(error));
    },
    sort: (config, orderby) => {
        const elm = document.getElementById(orderby);
        elm.classList.remove('fa-sort', "fa-sort-" + config.sort);
        config.orderby = orderby;
        config.sort = config.sort === "asc" ? "desc" : "asc";
        elm.classList.add("fa-sort-" + config.sort);
        table.get(config);
    },
    gotoPage(config, page) {
        config.page = page
        table.get(config);
    },
}

export default table;